import { useState, useEffect } from 'react';

const App = () => {
  const [usdToThbRate, setUsdToThbRate] = useState(0);
  const [satToUsdRate, setSatToUsdRate] = useState(0);
  const [btcToUsdRate, setBtcToUsdRate] = useState(0);
  const [btcToThbRate, setBtcToThbRate] = useState(0);
  const btcToSatRate = 100000000; // Fixed conversion rate

  const [usdValue, setUsdValue] = useState("");
  const [thbValue, setThbValue] = useState("");
  const [satValue1, setSatValue1] = useState("");
  const [thbValue2, setThbValue2] = useState("");
  const [satValue2, setSatValue2] = useState("");
  const [usdValue2, setUsdValue2] = useState("");
  const [satValue3, setSatValue3] = useState("");
  const [btcValue, setBtcValue] = useState("");
  const [btcValueUsd, setBtcValueUsd] = useState("");
  const [usdValueBtc, setUsdValueBtc] = useState("");
  const [btcValueThb, setBtcValueThb] = useState("");
  const [thbValueBtc, setThbValueBtc] = useState("");

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd,thb"
        );
        const data = await response.json();
        setUsdToThbRate(data.bitcoin.thb / data.bitcoin.usd);
        setSatToUsdRate(data.bitcoin.usd / btcToSatRate);
        setBtcToUsdRate(data.bitcoin.usd);
        setBtcToThbRate(data.bitcoin.thb);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };
    fetchExchangeRates();
  }, []);

  const formatNumber = (num: number | string) => {
    const parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  const handleChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    converter: (value: number) => void
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/,/g, "");
    if (!isNaN(Number(value)) && value !== "") {
      setter(value);
      const numericValue = Number(value);
      converter(numericValue);
    } else {
      setter("");
    }
  };

  const handleUsdChange = handleChange(setUsdValue, (value) => {
    setThbValue((value * usdToThbRate).toFixed(2));
  });

  const handleThbChange = handleChange(setThbValue, (value) => {
    setUsdValue((value / usdToThbRate).toFixed(2));
  });

  const preventDecimalInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  const handleSatChange1 = handleChange(setSatValue1, (value) => {
    setThbValue2((value * satToUsdRate * usdToThbRate).toFixed(2));
  });

  const handleThbChange2 = handleChange(setThbValue2, (value) => {
    setSatValue1((value / (satToUsdRate * usdToThbRate)).toFixed(0));
  });

  const handleSatChange2 = handleChange(setSatValue2, (value) => {
    setUsdValue2((value * satToUsdRate).toFixed(4));
  });

  const handleUsdChange2 = handleChange(setUsdValue2, (value) => {
    setSatValue2((value / satToUsdRate).toFixed(0));
  });

  const handleSatChange3 = handleChange(setSatValue3, (value) => {
    setBtcValue((value / btcToSatRate).toFixed(8));
  });

  const handleBtcChange = handleChange(setBtcValue, (value) => {
    setSatValue3((value * btcToSatRate).toFixed(0));
  });

  const handleBtcChangeUsd = handleChange(setBtcValueUsd, (value) => {
    setUsdValueBtc((value * btcToUsdRate).toFixed(2));
  });

  const handleUsdChangeBtc = handleChange(setUsdValueBtc, (value) => {
    setBtcValueUsd((value / btcToUsdRate).toFixed(8));
  });

  const handleBtcChangeThb = handleChange(setBtcValueThb, (value) => {
    setThbValueBtc((value * btcToThbRate).toFixed(2));
  });

  const handleThbChangeBtc = handleChange(setThbValueBtc, (value) => {
    setBtcValueThb((value / btcToThbRate).toFixed(8));
  });

  return (
    <div className="min-h-screen w-full flex justify-center items-start" style={{ backgroundColor: '#232323' }}>
      <div className="grid grid-cols-1 gap-3 max-w-md p-5">
        {/* 1st Orange Box */}
        <div className="bg-orange-500 p-2 rounded-2xl shadow-md w-full flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-1 text-white text-center">USD ➜ THB</h2>
          <input type="text" value={formatNumber(usdValue)} onChange={handleUsdChange} placeholder="USD" className="w-4/5 mx-auto p-1 mb-1 border border-gray-300 rounded-lg" />
          <input type="text" value={formatNumber(thbValue)} onChange={handleThbChange} placeholder="THB" className="w-4/5 mx-auto p-1 border border-gray-300 rounded-lg" />
          <p className="text-gray-200 text-xs mt-1 text-center">1 USD = {formatNumber(usdToThbRate.toFixed(2))} THB</p>
        </div>
        
        {/* 2nd Orange Box */}
        <div className="bg-orange-500 p-2 rounded-2xl shadow-md w-full flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-1 text-white text-center">SAT ➜ THB</h2>
          <input type="text" value={formatNumber(satValue1)} onChange={handleSatChange1} onKeyPress={preventDecimalInput} placeholder="satoshi" className="w-4/5 mx-auto p-1 mb-1 border border-gray-300 rounded-lg" />
          <input type="text" value={formatNumber(thbValue2)} onChange={handleThbChange2} placeholder="THB" className="w-4/5 mx-auto p-1 border border-gray-300 rounded-lg" />
          <p className="text-gray-200 text-xs mt-1 text-center">1 THB = {formatNumber((1 / (satToUsdRate * usdToThbRate)).toFixed(0))} sats</p>
        </div>
        
        {/* 3rd Orange Box */}
        <div className="bg-orange-500 p-2 rounded-2xl shadow-md w-full flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-1 text-white text-center">SAT ➜ USD</h2>
          <input type="text" value={formatNumber(satValue2)} onChange={handleSatChange2} onKeyPress={preventDecimalInput} placeholder="satoshi" className="w-4/5 mx-auto p-1 mb-1 border border-gray-300 rounded-lg" />
          <input type="text" value={formatNumber(usdValue2)} onChange={handleUsdChange2} placeholder="USD" className="w-4/5 mx-auto p-1 border border-gray-300 rounded-lg" />
          <p className="text-gray-200 text-xs mt-1 text-center">1 USD = {formatNumber((1 / satToUsdRate).toFixed(0))} sats</p>
        </div>

         {/* 6th Orange Box */}
        <div className="bg-orange-500 p-2 rounded-2xl shadow-md w-full flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-1 text-white text-center">BTC ➜ THB</h2>
          <input type="text" value={formatNumber(btcValueThb)} onChange={handleBtcChangeThb} placeholder="BTC" className="w-4/5 mx-auto p-1 mb-1 border border-gray-300 rounded-lg" />
          <input type="text" value={formatNumber(thbValueBtc)} onChange={handleThbChangeBtc} placeholder="THB" className="w-4/5 mx-auto p-1 border border-gray-300 rounded-lg" />
          <p className="text-gray-200 text-xs mt-1 text-center">1 BTC = {formatNumber(btcToThbRate)} THB</p>
        </div>
        
        {/* 5th Orange Box */}
        <div className="bg-orange-500 p-2 rounded-2xl shadow-md w-full flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-1 text-white text-center">BTC ➜ USD</h2>
          <input type="text" value={formatNumber(btcValueUsd)} onChange={handleBtcChangeUsd} placeholder="BTC" className="w-4/5 mx-auto p-1 mb-1 border border-gray-300 rounded-lg" />
          <input type="text" value={formatNumber(usdValueBtc)} onChange={handleUsdChangeBtc} placeholder="USD" className="w-4/5 mx-auto p-1 border border-gray-300 rounded-lg" />
          <p className="text-gray-200 text-xs mt-1 text-center">1 BTC = {formatNumber(btcToUsdRate)} USD</p>
        </div>

        {/* 4th Orange Box */}
        <div className="bg-orange-500 p-2 rounded-2xl shadow-md w-full flex flex-col justify-center">
          <h2 className="text-lg font-bold mb-1 text-white text-center">BTC ➜ SAT</h2>
          <input type="text" value={formatNumber(btcValue)} onChange={handleBtcChange} placeholder="BTC" className="w-4/5 mx-auto p-1 mb-1 border border-gray-300 rounded-lg" />
          <input type="text" value={formatNumber(satValue3)} onChange={handleSatChange3} onKeyPress={preventDecimalInput} placeholder="satoshi" className="w-4/5 mx-auto p-1 border border-gray-300 rounded-lg" />
          <p className="text-gray-200 text-xs mt-1 text-center">1 BTC = {formatNumber(btcToSatRate)} sats</p>
        </div>

         <p className="text-gray-400 text-xs mt-4 text-center">
          satoshi & chill<br /> ≈ vof ({" "}
          <a href="https://x.com/V0FVY" target="_blank" rel="noreferrer">𝕏</a>{" "}
          |{" "}
          <a href="https://primal.net/p/npub19alg4fzmjuq4syujkl7hwnunq6pxljev5aq6ezue506jqgwynadqu3rf5g" target="_blank" rel="noreferrer">NOSTR</a>{" "}
          )
        </p>
        
      </div>
    </div>
  );
};

export default App;
